import React, { useState, useEffect } from 'react';
import Layout from '../components/layout'
import Seo from "../components/seo";

import JSONData from "../../content/timeline.json"

import left from '../assets/svg/arrow-left.svg';
import mouse from '../assets/svg/icon-mouse.svg';
import right from '../assets/svg/arrow-right.svg';

import iconArrow from "../assets/svg/icon-right-arrow.svg";

import waves from "../assets/svg/waves-long.svg";

const Timeline = () => {

  const pathIMG = '../../timeline/';

  const [dataType, setDataType] = useState(null);
  const [fade, setFade] = useState(null)
  const [dotActive, setDotActive] = useState(null)
  const [dataContent, setDataContent] = useState({
    date: null,
    context: null,
    title: null,
    picture: null,
    quote: null,
    url: null
  });

  const { picture, url } = dataContent;

  useEffect(() => {
    setDataType(null);
    setFade('fadeIn');

    handleHover({
      type: 0,
      content: {
      date: "18 de octube 2019",
      title: "Estallido social, masivas manifestaciones y disturbios en Santiago y propagados a todas las regiones."
    }
    }, 0);

    const scrollContainer = document.querySelector('.timeline');

    scrollContainer.addEventListener('wheel', (evt) => {
      evt.preventDefault();
      scrollContainer.scrollLeft += evt.deltaY;
    });
  }, []);

  const handleHover = (item, index) => {
    setDataContent({
      date: item.content.date,
      context: item.content.context,
      title: item.content.title,
      picture: item.content.picture,
      quote: item.content.quote,
      url: item.content.url
    });
    
    if (index !== dotActive) {
      setFade('fadeIn');
    }
    
    setDataType(item.type);
    setDotActive(index);
  }
  const handleLeave = () => {
    setFade(null);
  }

  return (
    <Layout>
      <Seo title="Línea de tiempo" />
      <div className="timeline">

        {
          dataType !== null && (
            <div className={`infobox animate__animated animate__${fade}`}>
              <div className="quote">
                {
                  (dataType === 1 && picture !== "") && (
                    <div className="picture animate__animated animate__fadeIn">
                      <img src={`${pathIMG}${picture}`} alt={'picture'} />
                    </div>
                  )
                }
                <div className="quote-block">
                  {
                    dataType === 0 && (
                      <>
                        <p className="date mb-0 animate__animated animate__fadeIn">{dataContent.date}:</p>
                        <p className="animate__animated animate__fadeIn">{dataContent.title}</p>
                      </>
                    )
                  }
                  {
                    dataType === 1 && (
                      <>
                        <p className="date mb-0 animate__animated animate__fadeIn">{dataContent.date}:</p>
                        <p className="animate__animated animate__fadeIn">{dataContent.quote}</p>
                        {
                          url !== "" && (
                            <div className="more-row animate__animated animate__fadeIn">
                              <a href={url} target="_blank" className="btn btn-primary btn-sm rounded-pill">Ver más <img src={iconArrow} width="14" className="m-0" alt="arrow" /></a>
                            </div>
                          )
                        }
                      </>
                    )
                  }
                  {
                    dataType === 2 && (
                      <>
                        <p className="date mb-0 animate__animated animate__fadeIn">{dataContent.date}:</p>
                        <p className="animate__animated animate__fadeIn">{dataContent.context}</p>
                      </>
                    )
                  }
                  {
                    dataType === 3 && (
                      <>
                        <p className="date mb-0 animate__animated animate__fadeIn">{dataContent.date}:</p>
                        <p className="animate__animated animate__fadeIn">{dataContent.title}</p>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          )

        }

        <div className="waves">
          <img src={waves} className="m-0" alt="waves" />

          <div className="dots-wrapper">
            {
              JSONData.map((_, i) => {
                
                const { type } = _;
                const setColor = (type) => {
                  switch (type) {
                    case 0:
                      return '#ff6932';
                    case 1:
                      return '#ffd154';
                    case 2:
                      return '#8ad693';
                    default:
                      return '#ffb370';
                  }
                }

                return (
                  <div
                    key={i}
                    className={`dot dot-index-${ i } ${ dotActive === i ? 'active' : '' }`}
                    style={{
                      backgroundColor: setColor(type),
                      boxShadow: `0 4px 10px 2px ${setColor(type)}40`
                    }}  
                    onMouseOver={() => handleHover(_, i)}
                    onMouseLeave={handleLeave}>
                    <span className="dot-inner"></span>
                  </div>
                )
              }
              )
            }

          </div>

        </div>
        <div className="controls-timeline">
          <div className="left">
            <img src={left} alt="left" />
          </div>
          <div className="scroll">
            <img width={18} src={mouse} alt="" />
            NAVEGACIÓN POR SCROLL
          </div>
          <div className="right">
            <img src={right} alt="right" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Timeline;